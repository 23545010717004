:root {
  --ganttChartContractFirst: #5188C8;
  --ganttChartContractSecond: #C87E51;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*main (root) App CSS - NINCS HASZNÁLVA*/
.mainApp {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*login-page*/
.site-card-wrapper {
  padding: 35px;
  background: #ececec;
}

.ant-card-head {
  color: white !important;
  background-color: #264a60 !important;
}

.ant-card-head-title {
  text-align: center !important;
}

.ant-alert {
  margin: 20px !important;
}

.ant-alert-message {
  text-align: center;
}

/*server validation failed*/
.server-validation-failed {
  text-align: left !important;
}

/*query-input-field CSS*/
.query-field {
  margin: 6px 0;
}

/*ibm-gantt-chart CSS --!! DO NOT REMOVE THESE CSS !!--*/
.ibm-gantt-chart-react {
  max-width: 100%;
  height: 750px;
}

.gantt-tooltip {
  background-color: #264a60 !important;
  position: absolute !important;
  max-width: 60% !important;
}

.gantt-tooltip > h2 {
  color: #ffffff !important;
}

.gantt-toolbar .input-box {
  color: #264a60 !important;
}

.gantt-toolbar .input-box input {
  color: #264a60 !important;
}

.contract-chart-100 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 100%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-99 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 99%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-98 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 98%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-97 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 97%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-96 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 96%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-95 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 95%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-94 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 94%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-93 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 93%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-92 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 92%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-91 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 91%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-90 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 90%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-89 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 89%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-88 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 88%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-87 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 87%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-86 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 86%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-85 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 85%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-84 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 84%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-83 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 83%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-82 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 82%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-81 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 81%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-80 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 80%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-79 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 79%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-78 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 78%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-77 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 77%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-76 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 76%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-75 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 75%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-74 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 74%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-73 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 73%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-72 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 72%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-71 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 71%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-70 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 70%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-69 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 69%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-68 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 68%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-67 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 67%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-66 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 66%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-65 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 65%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-64 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 64%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-63 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 63%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-62 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 62%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-61 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 61%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-60 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 60%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-59 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 59%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-58 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 58%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-57 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 57%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-56 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 56%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-55 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 55%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-54 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 54%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-53 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 53%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-52 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 52%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-51 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 51%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-50 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 50%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-49 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 49%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-48 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 48%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-47 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 47%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-46 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 46%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-45 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 45%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-44 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 44%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-43 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 43%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-42 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 42%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-41 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 41%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-40 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 40%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-39 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 39%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-38 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 38%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-37 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 37%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-36 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 36%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-35 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 35%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-34 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 34%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-33 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 33%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-32 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 32%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-31 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 31%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-30 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 30%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-29 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 29%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-28 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 28%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-27 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 27%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-26 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 26%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-25 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 25%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-24 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 24%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-23 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 23%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-22 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 22%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-21 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 21%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-20 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 20%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-19 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 19%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-18 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 18%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-17 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 17%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-16 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 16%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-15 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 15%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-14 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 14%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-13 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 13%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-12 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 12%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-11 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 11%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-10 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 10%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-9 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 9%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-8 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 8%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-7 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 7%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-6 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 6%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-5 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 5%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-4 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 4%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-3 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 3%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-2 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 2%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-1 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 1%, var(--ganttChartContractSecond) 0%) !important;
}

.contract-chart-0 {
  background: linear-gradient(to right, var(--ganttChartContractFirst) 0%, var(--ganttChartContractSecond) 0%) !important;
}

/*antd layout CSS*/
.ant-layout-header {
  height: 48px !important;
  line-height: 48px !important;
  color: #ffffff !important;
  background: #264a60 !important;
}

.header-ppc-logo {
  margin: 0 10px 0 0;
  float: left;
}

.site-layout-content {
  padding: 20px 50px;
}

.site-layout-content-main-div {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.site-layout-footer {
  text-align: center;
}

.ant-layout-footer {
  padding: 0 50px !important;
}

/*antd menu CSS*/
.site-layout-menu {
  background-color: #264a60 !important;
  line-height: 48px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #325c80 !important;
}

.ant-menu-item-active {
  background-color: #4682B4 !important
}

/*antd divider CSS*/
.ant-divider-horizontal {
  margin: 16px 0 !important;
}

/*antd collapse CSS*/
.custom-collapse {
  background: #e6f7ff !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-weight: 500 !important;
}

/*antd button CSS*/
button[class*="ant-btn-primary"] {
  background: #264a60;
  border-color: #264a60 !important;
}

button[class*="ant-btn-primary"]:hover {
  background: #264a60;
  border-color: #264a60 !important;
}

button[class*="ant-btn-primary"]:focus {
  background: #264a60;
  border-color: #264a60 !important;
}

.custom-delete-button {
  background: #ff4d4fad !important;
  border-color: #ff4d4fad !important;
}

.custom-edit-button {
  background: #bae7ff !important;
  border-color: #bae7ff !important;
}

.custom-create-button {
  background: #b7eb8f !important;
  border-color: #b7eb8f !important;
}

/*antd table CSS*/
.ant-table {
  display: block;
  overflow-x: auto;
  /*white-space: nowrap;*/
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #E8E8E8;
}

tbody[class*="ant-table-tbody"] > tr:hover > td {
  background: #e6f7ff !important;
}

thead[class*="ant-table-thead"] th {
  color: #ffffff !important;
  background-color: #264a60 !important;
}

/*antd switch component CSS*/
.ant-switch-checked {
  background-color: #264a60 !important;
}

.custom-switch-label {
  margin: -1.30rem 0 0 2.75rem;
}

/*antd input CSS*/
.ant-input {
  height: 29px !important;
}

textarea.ant-input {
  height: 100px !important;
}

/*antd datePicker input CSS*/
.ant-picker-input > input {
  width: 235px !important;
  height: 29px !important;
}

/*react-select CSS*/
.select__placeholder {
  color: #c5c5ca !important;
}

.multi-select__placeholder {
  color: #c5c5ca !important;
}

.contractExcelExportButton {
  color: #264a60;
}

/*EDITOR - PAGE*/
.procedureDataDetailsElement {
  margin: 0 0 40px 0;
}

.formCalculateButton {
  margin: 0 0 1.5em 0;
}

/*QUERY - PAGE*/
.personal-queries {
  margin: 31px 0 0 0;
}

.custom-pq-save-button {
  background: #bae7ff !important;
  border-color: #bae7ff !important;
}

.custom-pq-delete-button {
  background: #ff4d4fad !important;
  border-color: #ff4d4fad !important;
}

.ant-popover-disabled-compatible-wrapper {
  background: white !important;
}

/*OTHER - CSS*/
.custom-mail-to-button {
  background: #f0f2f5 !important;
  border-color: #f0f2f5 !important;
}

.custom-tooltip {
  margin: 0 0 0 0.75rem;
}

.switch-demo-mode {
  background-color: #264a60 !important;
}

/*TABLE - SearchPage - DataTable*/
tbody[class*="ant-table-tbody"] > tr > td {
  border: 1px solid lightgrey;
}

div[class*="ant-table-tbody"] > table {
  border-collapse: collapse;
}

/*TABLE - SearchPage - GanttChart-Tooltip*/
/*div[class*="gantt-tooltip"] > div > table > tbody > tr > td {*/
/*  border-bottom: 1px solid grey;*/
/*}*/

div[class*="gantt-tooltip"] > div > table > tbody > tr:hover {
  background-color: #C87E51;
}

/*TABLE - Statistics*/
.ant-table-cell-with-append {
  font-weight: bold;
}

.neak-statistics-table > div > div > div > div > div > table > tbody > tr:last-child {
  font-weight: bold;
}

.neak-statistics-table > div > div > div > div > div > table > tbody > tr > td:last-child {
  font-weight: bold;
}

.neak-statistics-table > div > div > div > div > div > table > thead > tr > th {
  padding: 6px 3px !important;
  text-align: center !important;
}

.neak-statistics-table > div > div > div > div > div > table > tbody > tr > td:first-child {
  font-weight: bold;
  padding: 1px 4px;
  text-align: left !important;
}

.neak-statistics-table > div > div > div > div > div > table > tbody > tr > td {
  padding: 5px 5px;
  text-align: center !important;
}

/*SCROLLBAR*/
::-webkit-scrollbar-track
{
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

::-webkit-scrollbar
{
  background-color: #f5f5f5;
  height: 10px; /* height of horizontal scrollbar */
  width: 10px; /* width of vertical scrollbar */
}

::-webkit-scrollbar-thumb
{
  background-color: lightgrey;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

/*.nav{*/
/*  list-style-type: none;*/
/*  width: 500px;*/
/*  display: flex;*/
/*  justify-content: space-evenly;*/
/*}*/

/*a{*/
/*  text-decoration: none;*/
/*  color: inherit;*/
/*}*/

/*.formError {*/
/*  color: red;*/
/*}*/

/*!*FROM https://codesandbox.io/s/zKrK5YLDZ?file=/helper.css:0-1407*!*/
/** {*/
/*  box-sizing: border-box;*/
/*}*/

/*body {*/
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,*/
/*  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
/*  font-size: 14px;*/
/*  line-height: 1.5;*/
/*  color: #24292e;*/
/*  background-color: #fff;*/
/*}*/

/*.app {*/
/*  margin: 2rem;*/
/*}*/

/*a {*/
/*  color: #08c;*/
/*}*/

/*input {*/
/*  padding: .5rem;*/
/*  font-size: 16px;*/
/*  width: 100%;*/
/*  display: block;*/
/*  border-radius: 4px;*/
/*  border: 1px solid #ccc;*/
/*}*/

/*input:focus {*/
/*  border-color: #007eff;*/
/*  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),*/
/*  0 0 0 3px rgba(0, 126, 255, 0.1);*/
/*  outline: none;*/
/*}*/

/*input.error {*/
/*  border-color: red;*/
/*}*/

/*label {*/
/*  font-weight: bold;*/
/*  display: block;*/
/*  margin-bottom: .5rem;*/
/*}*/

/*.input-feedback-error {*/
/*  color: red;*/
/*  margin-top: .25rem;*/
/*}*/

/*button {*/
/*  max-width: 150px;*/
/*  margin: 20px 0;*/
/*  padding: 12px 20px;*/
/*  border-style: none;*/
/*  border-radius: 5px;*/
/*  background-color: #08c;*/
/*  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);*/
/*  font-size: 17px;*/
/*  font-weight: 500;*/
/*  color: #fff;*/
/*  cursor: pointer;*/
/*  outline: none;*/
/*  -webkit-appearance: none;*/
/*}*/

/*button:disabled {*/
/*  opacity: .5;*/
/*  cursor: not-allowed !important;*/
/*}*/

/*button + button {*/
/*  margin-left: .5rem;*/
/*}*/

/*button.outline {*/
/*  background-color: #eee;*/
/*  border: 1px solid #aaa;*/
/*  color: #555;*/
/*}*/
